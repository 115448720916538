<template>
	<div style="background: #fff;" v-if="result">
		<div>
			<div style="padding: 10px 20px;background-color: #f5f5f5;margin: 20px 0;">详细信息</div>
			<div style="padding: 0 20px;">
				<div style="margin-bottom: 20px;">选择商品: <el-button type="primary" style="width:100px;" @click="handleSelectProDialog">选择商品</el-button>
				</div>
				<el-table border :data="selectProduct" style="width: 100%;margin-bottom: 20px;">
					<el-table-column label="商品" width="450px">
						<template slot-scope="scope">
							<div style="display: flex;align-items: center;">
								<img style="width: 60px;height: 60px;" :src="scope.row.mainImgUrl" />
								<div class="right" style="margin-left: 10px;">
									<div class="name">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.goodsName}}</pre>
									</div>
									<div style="margin-top: 5px;">{{scope.row.goodsCode}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="specValues" label="规格"></el-table-column>
					<el-table-column prop="goodsCount" label="数量">
						<template slot-scope="scope">
							<el-input-number v-model="goodsCount" @change="handleChange" :min="1" :max="100" label="商品数量"></el-input-number>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button style="color:#F56C6C;" @click="deleteSortPro(scope.row.id)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="padding: 0 20px;">
				<div style="display: flex;align-items: center;">用户收货地址：
					<div style="border: 1px solid #999; width: 350px;padding:6px 10px;">
						<el-dropdown @command="handleCommand" trigger="click">
							<span class="el-dropdown-link" style="width: 330px;display: flex;justify-content: space-between;">
								{{addressDesc}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item,index) in addressList" :key="index" :command="index" divided>
									<div>
										<div>{{item.receiverName}}|{{item.receiverPhone}}</div>
										<div>{{item.province}}{{item.city}}{{item.area}}{{item.detailAddress}}</div>
									</div>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<el-button type="primary" size="small" style="margin-left: 10px;" @click="reset">重选</el-button>
					<el-button type="primary" size="small" @click="addAddress">添加地址</el-button>
				</div>
			</div>
			<div style="padding-left: 50px;font-size: 14px;" v-if="selectAddress">
				<div style="margin-top: 20px;">收货人姓名：{{selectAddress.receiverName}}</div>
				<div style="margin-top: 10px;">收货人手机：{{selectAddress.receiverPhone}}</div>
				<div style="margin-top: 10px;">收货人地址：{{selectAddress.province}}{{selectAddress.city}}{{selectAddress.area}}{{selectAddress.detailAddress}}</div>
			</div>
			<div style="display: flex;align-items: center;padding-left: 40px;font-size: 14px;margin-top: 20px;">
				<img :src="result.headUrl" alt="" style="width: 60px;height:60px;border-radius: 100%;">
				<div style="margin-left: 10px;">
					<div>昵称：{{result.userName}}</div>
					<div style="margin-top: 10px;">ID：{{result.userId}}</div>
				</div>
			</div>
			<el-button type="primary" style="width:100px;margin-left: 100px;margin-bottom: 50px;margin-top: 30px;" @click="createorder">创建订单</el-button>
		</div>
		<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1300px" class="dialog">
			<select-produce @getSelectList="getSelectList" :visible.sync="selectProDialog" v-if="selectProDialog"></select-produce>
		</el-dialog>

		<el-dialog title="添加地址" :visible.sync="addressDialog" width="700px" class="dialog">
			<div>
				<el-form :model="ruleForm" ref="ruleForm" label-width="150px">
					<div class="content">
						<el-form-item label="收货人姓名：" :inline-message="true" prop="receiverName" :rules="[{ required: true, message: '收货人不能为空', trigger: 'blur'}]">
							<el-input v-model="ruleForm.receiverName" style="width: 300px;" placeholder="最多输入20个字" maxlength="20"></el-input>
						</el-form-item>
						<el-form-item label="手机号：" :inline-message="true" prop="receiverPhone" :rules="[{ required: true, message: '收货人手机号不能为空', trigger: 'blur'}]">
							<el-input placeholder="请输入手机号" style="width: 300px;" v-model="ruleForm.receiverPhone" :show-word-limit="true"></el-input>
						</el-form-item>

						<el-form-item label="收货地址：" :inline-message="true" prop="address" :rules="[{ required: true, message: '省市区不能为空', trigger: 'blur'}]">
							<el-cascader v-model="ruleForm.address" :options="areaList" ref="cascader" :props="{ value: 'id' ,label:'name'}"></el-cascader>
						</el-form-item>

						<el-form-item label="详细地址：" :inline-message="true" prop="detailAddress" :rules="[{ required: true, message: '详细地址不能为空', trigger: 'blur'}]">
							<el-input type="textarea" :rows="2" placeholder="请输入内容,最多输入300字" :max="300" v-model="ruleForm.detailAddress"
							:show-word-limit="true" style="width: 300px;"></el-input>
						</el-form-item>
					</div>
				</el-form>
				<span slot="footer" class="dialog-footer" style="margin-top: 20px;display: flex;justify-content: flex-end;">
					<el-button @click="addressDialog=false">关 闭</el-button>
					<el-button type="primary" @click="addressMark('ruleForm')">确 认</el-button>
				</span>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import selectProduce from './selectMultiPro.vue'
	import apiList from '@/api/other'
	import util from '@/utils/util.js'
	export default {
		components: {
			selectProduce
		},
		data() {
			return {
				areaList: [],
				selectProDialog: false,
				selectProduct: [],
				id: 0,
				result: null,
				addressList: [],
				selectAddress: null,
				addressDesc: '选择地址',
				addressDialog: false,
				ruleForm: {
					id: '',
					receiverName: '', //收货人
					receiverPhone: '', //收货人电话
					detailAddress: '', //详细地址
					address: [], //地址
					addressCode: '', //地址编码
				},
				goodsCount:1
			}
		},
		beforeMount() {
			this.areaList = util.getAddressList();
		},
		created() {
			this.id = this.$route.query.id || 0;
			this.initData();
		},
		methods: {
			async initData() {
				let res = await apiList.createorderdetail({
					afterSaleId: +this.id
				})
				if (res.success) {
					this.result = res.data;
					this.getUserAddress();
				}
			},
			handleSelectProDialog() {
				this.selectProDialog = true
			},
			//获取选择商品的列表
			getSelectList(data) {
				this.goodsCount=1;
				this.selectProduct = data;
				this.selectProDialog = false;
			},
			// 删除指定商品
			deleteSortPro(id) {
				this.selectProduct = this.selectProduct.filter(t => {
					if (t.id != id) {
						return t
					}
				})
			},
			handleCommand(e) {
				console.log(e);
				this.selectAddress = this.addressList[+e];
				this.addressDesc = this.selectAddress.province + this.selectAddress.city + this.selectAddress.area + this.selectAddress
					.detailAddress;
			},
			handleChange(value) {
				console.log(value);
			},
			async getUserAddress() {
				let res = await apiList.userAddressList({
					userId: this.result.userId
				})
				if (res.success) {
					this.addressList = res.data;
				}
			},
			reset() {
				this.addressDesc = "选择地址";
				this.selectAddress = null;
			},
			addAddress() {
				this.addressDialog = true;
			},
			addressMark(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.saveAddress();
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			async createorder() {
				console.log("-----",this.selectAddress);
				if(!this.selectProduct.length){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择商品'
					});
					return;
				}
				if(!this.selectAddress){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择收货地址'
					});
					return;
				}
				let res = await apiList.createorder({
					addressId: this.selectAddress.addressId,
					afterSaleId: +this.id,
					goodsId: this.selectProduct[0].goodsId,
					quantity: this.goodsCount,
					skuId: this.selectProduct[0].skuId
				})
				if (res.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '订单创建成功'
					});
					
					
					// this.$router.back();
					
					
					this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
						(item) => {
							return item.path != this.$route.path;
						}
					);
					this.$router.push({
						path: '/order/refundAfterSale/exChangeRefundDetails?id='+this.id
					});
				}else{
					this.$message({
						showClose: true,
						type: 'error',
						message:res.alertMsg ? res.alertMsg: '订单创建失败'
					});
				}
			},
			async saveAddress() {
				let select = this.$refs.cascader.getCheckedNodes();
				let province = select[0].parent.parent.label;
				let city = select[0].parent.label;
				let area = select[0].label;
				let res = await apiList.useraddressadd({
					receiverPhone: this.ruleForm.receiverPhone,
					userId: this.result.userId,
					receiverName: this.ruleForm.receiverName,
					detailAddress: this.ruleForm.detailAddress,
					province: province,
					city: city,
					area: area,
				})
				if (res.success) {
					this.addressDialog = false;
					this.$message({
						showClose: true,
						type: 'success',
						message: '地址添加成功'
					});
					this.getUserAddress();
				}
			}
		}
	}
</script>

<style lang="less" scoped>
</style>
